import React from 'react';
import queryString from 'query-string';

import StoreData from '../../data/store';

import Container from '../Container';
import Loader from '../Placeholder/spinner';

import alertStyle from '../Alert/style';

import loadComponents from '../Loadable';

const Button = loadComponents('button');
const Layout = loadComponents('checkout-layout');
const Alert = loadComponents('alert');

export default class Checkout extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      orderId: null,
      currency: 'GBP',
      total: 0.0,
      error: false,
      success: false,
      payment: 0,
      lineItems: [],
      loading: true,
      appointment: false,
    };
    this.displaySuccess = this.displaySuccess.bind(this);
    this.displayError = this.displayError.bind(this);
    this.togglePayment = this.togglePayment.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.initCheckout();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  displayError = error => {
    if (this._isMounted) {
      this.setState({ error });
    }
  };

  displaySuccess = success => {
    if (this._isMounted) {
      this.setState({ success });
    }
  };

  togglePayment = (e, id) => {
    e.preventDefault();
    if (this._isMounted) {
      this.setState({ payment: id });
    }
  };

  initCheckout() {
    const { location } = this.props;
    const { search } = location;

    const queries = queryString.parse(search);

    if (search) {
      const orderId = queries.order;
      const appointment = queries.appointment;
      StoreData.getCheckout(orderId)
        .then(res => {
          const { status, currency, total, line_items } = res;
          if (res.error) {
            this.displayError(res.message);
          }
          if (status === 'pending') {
            this.setState({
              orderId,
              currency,
              total,
              lineItems: line_items,
              appointment,
            });
          }
          if (status === 'processing') {
            this.displaySuccess(
              `This application has been paid and requires no further action at this time. If you created an account you can view the status of your application from the account dashboard.`,
            );
          }
          if (status === 'completed') {
            this.displaySuccess(
              `This application has been completed and requires no further action action. If you created an account you can view the status of your application from the account dashboard.`,
            );
          }
        })
        .catch(err => this.displayError(err.message))
        .finally(() => this.setState({ loading: false }));
    } else {
      this.displayError(`Application ID not found please try again.`);
    }
  }

  displayCheckout() {
    const { location } = this.props;
    const {
      orderId,
      payment,
      currency,
      total,
      lineItems,
      appointment,
      error,
      success,
    } = this.state;

    if (orderId !== null) {
      const initial = location?.state?.order?.billing;
      const items = location?.state?.order?.line_items || lineItems;

      const layoutProps = {
        orderId,
        initial,
        payment,
        togglePayment: this.togglePayment,
        displayError: this.displayError,
        displaySuccess: this.displaySuccess,
        currency,
        total,
        items,
        appointment,
      };

      return (
        <Layout {...layoutProps}>
          {error && (
            <Alert style={alertStyle} error>
              {error}
            </Alert>
          )}
          {success && (
            <Alert style={alertStyle} success>
              {success}
            </Alert>
          )}
        </Layout>
      );
    }
    return (
      <Button to="/" primary>
        Return to Homepage
      </Button>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <Container>
        {loading ? (
          <div
            style={{
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
              marginLeft: `-30px`,
            }}
          >
            <Loader size="30px" />
            <p style={{ marginLeft: `30px` }}>Loading application status...</p>
          </div>
        ) : (
          this.displayCheckout()
        )}
      </Container>
    );
  }
}
