import React from 'react';
import { Loader } from '@web-passion/uikit';

export default function Spinner({ text, size = `16px`, style }) {
  return (
    <div style={{ marginLeft: text && `0.625rem`, ...style }}>
      <Loader size={size} />
    </div>
  );
}
