// import CryptoJS from 'crypto-js';

const StoreData = {
  // checkCoupon: id => {
  //   return couponRequest(id);
  // },
  getCheckout: id => {
    return checkoutRequest(`?include=${id}&per_page=1`);
  },
  createOrder: data => {
    return orderCreate(data);
  },
  addToOrder: (id, data) => {
    return orderAdd(id, data);
  },
  updateOrder: (id, data, download = false, payment) => {
    return orderUpdate(id, data, download, payment);
  },
  // paymentIntent: (orderId, total, currency) => {
  //   return createPaymentIntent(orderId, total, currency);
  // },
  paypalToken: () => {
    return getPaypalToken();
  },
  paypalCreate: (total, currency) => {
    return createPaypalOrder(total, currency);
  },
  paypalCapture: id => {
    return capturePaypalOrder(id);
  },
  encrypt: data => {
    return encryptData(data);
  },
  decrypt: data => {
    return decryptData(data);
  },
  downloadConfirm: (id, email) => {
    return confirmDownload(id, email);
  },
  downloadFile: id => {
    return fileDownload(id);
  },
  logRequest: message => {
    return requestLog(message);
  },
};

export default StoreData;

const shopID = 'VISA-APPLICATIONS';

const standardParams = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
};

function toBinary(string) {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  const charCodes = new Uint8Array(codeUnits.buffer);
  let result = '';
  for (let i = 0; i < charCodes.byteLength; i++) {
    result += String.fromCharCode(charCodes[i]);
  }
  return result;
}

function fromBinary(binary) {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  const charCodes = new Uint16Array(bytes.buffer);
  let result = '';
  for (let i = 0; i < charCodes.length; i++) {
    result += String.fromCharCode(charCodes[i]);
  }
  return result;
}

function encryptData(data) {
  const encrypted = toBinary(JSON.stringify(data));
  return btoa(encrypted);
}

function decryptData(data) {
  const decrypted = atob(data);
  const binary = fromBinary(decrypted);
  return JSON.parse(binary);
}

async function checkoutRequest(query) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}shop/checkout`;
  const params = {
    ...standardParams,
    body: JSON.stringify({
      query,
    }),
  };
  return (await fetch(url, params)).json();
}

function getFieldData(key, fields) {
  return fields.filter(
    x =>
      x.id === key ||
      x.name
        ?.replace(/[^A-Z0-9-]/gi, ' ')
        .replace(/\s+/g, '_')
        .toLowerCase() === key,
  );
}

async function orderCreate(data) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}shop/orders/create`;

  const address = {
    first_name: data.address.first_name,
    last_name: data.address.last_name,
    address_1: data.address.street_address,
    address_2: '',
    city: data.address.city,
    state: data.address.state,
    postcode: data.address.postcode,
    country: data.address.country,
  };

  const products = [
    {
      product_id: data.product.databaseId,
      quantity: data.product.quantity,
    },
  ];

  // Create array of all fields in form
  const allFields = [];
  data.form.forEach(item => {
    allFields.push(...item.fields);
  });

  // Create object using field id as the key containing the field label & value
  const applicationData = {};
  Object.keys(data.application).forEach(key => {
    if (key !== 'formKey') {
      const field = getFieldData(key, allFields);
      const fieldVal = data.application[key];

      applicationData[key] = {
        label: field[0].name,
        value: fieldVal,
      };
    }
  });

  const landing_page = localStorage.getItem('landing_page') || false;

  const orderData = {
    currency: data.currency,
    set_paid: false,
    billing: {
      ...address,
      email: data.address.email,
      phone: data.address.phone,
    },
    shipping: {
      ...address,
    },
    line_items: products,
    meta_data: [
      {
        key: 'application_form',
        value: applicationData,
      },
      {
        key: 'user_data',
        value: data.user,
      },
      {
        key: 'landing_page',
        value: landing_page,
      },
    ],
  };

  const params = {
    ...standardParams,
    body: JSON.stringify({ data: encryptData(orderData) }),
  };

  const order_note = `Application submitted.\nIP Address: ${data.user?.ip}\nLocation: ${data.user?.city}, ${data.user?.region}, ${data.user?.country_name} (${data.user?.latitude}, ${data.user?.longitude})`;

  const result = await fetch(url, params)
    .then(res => res.json())
    .then(res => decryptData(res.data));

  await addNote(result.id, order_note);

  return result;
}

async function orderAdd(id, data) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}shop/orders/update`;

  // Create array of all fields in form
  const allFields = [];
  data.form.forEach(item => {
    allFields.push(...item.fields);
  });

  // Create object using field id as the key containing the field label & value
  const applicationData = {};
  Object.keys(data.application).forEach(key => {
    if (key !== 'formKey') {
      const field = getFieldData(key, allFields);
      const fieldVal = data.application[key];

      applicationData[key] = {
        label: field[0].name,
        value: fieldVal,
      };
    }
  });

  const products = [
    {
      product_id: data.product.databaseId,
      quantity: data.product.quantity,
      meta_data: [
        {
          key: 'application_form',
          value: applicationData,
        },
      ],
    },
  ];

  const orderData = {
    line_items: products,
  };

  const request = {
    id,
    data: orderData,
  };

  const params = {
    ...standardParams,
    body: JSON.stringify({ data: encryptData(request) }),
  };

  const result = await fetch(url, params)
    .then(res => res.json())
    .then(res => decryptData(res.data));

  const order_note = `Additional application added to order.\nIP Address: ${data?.user?.ip}\nLocation: ${data?.user?.city}, ${data?.user?.region}, ${data?.user?.country_name} (${data?.user?.latitude}, ${data?.user?.longitude})`;

  await addNote(id, order_note);

  return result;
}

async function addNote(id, note) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}shop/orders/notes`;
  const request = {
    id,
    note,
  };
  const params = {
    ...standardParams,
    body: JSON.stringify({ data: encryptData(request) }),
  };

  return (await fetch(url, params)).json();
}

async function orderUpdate(id, data, download, payment) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}shop/orders/update`;

  const user = await fetch('https://ipapi.co/json/')
    .then(response => response.json())
    .catch(() =>
      alert(
        `Unable to update application. Please disable any adblockers or VPN's to proceed.`,
      ),
    );

  const order_note = `Application ${
    download ? `downloaded by customer` : `fee paid`
  }.\nIP Address: ${user?.ip}\nLocation: ${user?.city}, ${user?.region}, ${
    user?.country_name
  } (${user?.latitude}, ${user?.longitude})`;

  const note = await addNote(id, order_note);

  if (!download) {
    const orderData = {
      payment_method: 'paypal',
      payment_method_title: 'Paypal',
      set_paid: true,
      transaction_id: data,
    };

    const request = {
      id,
      data: orderData,
    };

    const params = {
      ...standardParams,
      body: JSON.stringify({ data: encryptData(request) }),
    };

    const result = await fetch(url, params)
      .then(res => res.json())
      .then(res => decryptData(res.data));

    return result;
  }

  return note;
  // return (await fetch(url, params)).json();
}

async function getPaypalToken() {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}payment/paypal/token`;
  const params = {
    ...standardParams,
    body: JSON.stringify({}),
  };
  return (await fetch(url, params)).json();
}

async function createPaypalOrder(total, currency) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}payment/paypal/create`;
  const params = {
    ...standardParams,
    body: JSON.stringify({ total, currency }),
  };
  return (await fetch(url, params)).json();
}

async function capturePaypalOrder(id) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}payment/paypal/capture`;
  const params = {
    ...standardParams,
    body: JSON.stringify({ id }),
  };
  return (await fetch(url, params)).json();
}

async function confirmDownload(id, email) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}shop/download/confirm`;
  const request = { id, email };
  const params = {
    ...standardParams,
    body: JSON.stringify({ data: encryptData(request) }),
  };

  const result = await fetch(url, params)
    .then(res => res.json())
    .then(res => decryptData(res.data));

  return result;

  // return (await fetch(url, params)).json();
}

async function fileDownload(id) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}shop/download`;

  const params = {
    ...standardParams,
    body: JSON.stringify({ data: encryptData({ id }) }),
  };

  const result = await fetch(url, params).then(res => res.blob());
  // .then(res => decryptData(res.data));

  return result;

  // return (await fetch(url, params)).json();
}

async function requestLog(message) {
  const url = `${process.env.GATSBY_FUNCTIONS_URL}log`;

  const params = {
    ...standardParams,
    body: JSON.stringify({ message }),
  };

  const result = await fetch(url, params).then(res => res.json());

  return result;

  // return (await fetch(url, params)).json();
}

// async function couponRequest(id) {
//   const url = `${process.env.GATSBY_FUNCTIONS_URL}shop/coupons`;
//   const params = {
//     ...standardParams,
//     body: JSON.stringify({ code: id }),
//   };
//   return (await fetch(url, params)).json();
// }
